<template>
  <div class="marketplace" :class="{ marketplace_open : marketEnabled }">
    <div @click="clickOutside" class="marketplace__fixed">
      <div class="marketplace__modal uk-container uk-container-small">
        <div class="marketplace__container">
          <div class="marketplace__content">
            <div class="marketplace__close" @click="hideForm">
                <!-- <SvgClose viewBox="0 0 20 20" /> -->
                <img src="../assets/img/svg/clouseR.png" alt="clouseR.png" class= "icon-close">
            </div>
            <div class="marketplace__title">
              Выберите маркетплейс 
            </div>
            <div class="marketplace__choose">
              <a :href="links.ozon" target="_blank">
                <button class="button button-market button-ozon" aria-label="Ozon">
                 
                  <img src="../assets/img/svg/ozon.png" alt="ozon.png" class= "icon-market market_ozon">
                </button>
              </a>

              <a :href="links.wildberries" target="_blank">
                <button class="button button-market button-wb" aria-label="Wildberries">
                 
                  <img src="../assets/img/svg/wildberries.png" alt="icon-wildberries.png" class= "icon-market market_wildberries">
                </button>
              </a>
     
              <a :href="links.sber" target="_blank">
                <button class="button button-market button-sber" aria-label="Sber">
                
                   <img src="../assets/img/svg/SBER_2.png" alt="icon-yamarket.png" class= "icon-market market_yanmarket">
                </button>
              </a>

             


            </div>
          </div>
        </div>
       </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'marketplace-component',


  computed: {
    marketEnabled() {
        const isEnabled = this.$store.state.modalNameEnabled === "marketplace";
        //console.log("Modal enabled:", isEnabled) // Отладка
        return isEnabled;
    },
    links() {
      //const product = this.$store.state.productMarket
      
      let ozon = process.env.VUE_APP_LINK_OZON
      let wildberries = process.env.VUE_APP_LINK_WILDBERRIES    
      let sber = process.env.VUE_APP_LINK_SBER 
      //let text = product?.replace(/[ ()$&+]/g, "+") ?? 'tcl'

      ozon = `${ozon}/seller/gk-yunikom-80642/avtotovary-8500/tcl-30955002/?miniapp=seller_80642&opened=brand`;
      wildberries = `${wildberries}/seller/38336?sort=popular&page=1&fbrand=20817`;
      sber = `${sber}/catalog/?q=tcl%20масло`;
      
      return {
        ozon,
        wildberries,
        sber
      }
    }
  },
  watch: {
    marketEnabled(val) {
      let body = document.getElementById("app").parentElement;
      if (val) body.classList.add('modal-open');
      else body.classList.remove('modal-open');
    }
  },
  methods: {
    clickOutside(event) {
      if (!event.target.closest('.marketplace__modal')) {
        this.hideForm()
      }
    },
    hideForm() {
      if (this.marketEnabled === true) {
        this.$store.commit('SET_MODAL_NAME_ENABLED', false)
      }
    },
  },
}
</script>