<template>
  <div id="app">
    <main class="main">
      <Header />
      <Router-view @feedback="feedbackState($event.enabled)" />
      <Footer @feedback="feedbackState($event.enabled)" />
      <Feedback
          :fixed="true"
          :opened="enabled"
          @close="feedbackState($event.enabled)"
      />
        <!-- modal forms -->
        <Marketplace  />
    
        <!-- modal forms -->
    </main>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from "./components/Footer";
import Feedback from "./components/Feedback";
import Marketplace from './components/Marketplace';

export default {
  name: 'App',
  components: {
    Feedback,
    Footer,
    Header,
    Marketplace
  },
  data() {
    return {
      enabled: false,
      marketplace: false,
    }
  },
  methods: {
    feedbackState(enabled) {
      this.enabled = enabled
      const body = document.querySelector("body")
      
      if (enabled) body.style.overflow = "hidden"
      else body.style.overflow = "auto"
    }
  },
}
</script>

