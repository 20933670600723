<template>
  <div id="partners" class="partners partners_inner __animated">
    <div class="partners__container">
      <div class="partners__inside">
        <div class="container">
            <div class="partner-container__liner">
                <div class="title partners__title">
                    <h2>
                        <span>Где купить/заменить продукцию TCL?</span>
                    </h2>
                </div>
                <div class="partners__button-block"> 
                    <button class="button button-black" @click="callModal('marketplace')">
                            Купить на маркетплейсах
                    </button>
                </div>
            </div>
        </div>

        <div class="container">
          <div class="container__liner">
            <div class="partners__option_info"> - Авторизованные пункты замены масла (АПЗМ)</div>
          </div>
        </div>
        <div class="partners__content">
          <div id="mapForm">

            <div class="partners__sidebar" v-show="showMobileTab('Partners')">
              <div class="partners__list">
                <div class="mCustomScrollBox" style="max-height: none">

                  <div v-if="loading" class="loader">
                    <div class="loader-map">
                      <div class="loader-map-box box-flex">
                        <div class="load-skeleton item item-bg"></div>
                        <div class="load-skeleton item item-md"></div>
                        <div class="load-skeleton item item-md"></div>
                        <div class="load-skeleton item item-md"></div>
                      </div>
                      <div class="loader-map-box box-flex">
                        <div class="load-skeleton item item-bg"></div>
                        <div class="load-skeleton item item-md"></div>
                        <div class="load-skeleton item item-md"></div>
                        <div class="load-skeleton item item-md"></div>
                      </div>
                      <div class="loader-map-box box-flex">
                        <div class="load-skeleton item item-bg"></div>
                        <div class="load-skeleton item item-md"></div>
                        <div class="load-skeleton item item-md"></div>
                        <div class="load-skeleton item item-md"></div>
                      </div>
                    </div>
                  </div>

                  <div v-else class="mCSB_container">
                    <div
                        class="locations__point"
                        v-for="(partner, index) in partners"
                        :key="index"
                        :class="{ 'locations__point_active': partner.coords === selectedMarker }"
                        @click="selectPartner(partner.coords)"
                    >
                      <div class="locations__header" :class="{ 'locations__header_auth': partner.is_authorised }">{{ partner.signboard }}</div>
                      <div class="locations__address">{{partner.address}}</div>
                      <div class="locations__phone">
                        <a v-for="(phone, index) in partner.phone" :key="index" :href="`tel:${phone}`">{{phone}}</a>
                      </div>
                      <div class="locations__url">
                        <a v-for="(url, index) in partner.url" :key="index" :href="url">{{url}}</a>
                      </div>
                      <div class="locations__email">
                        <a v-for="(email, index) in partner.email" :key="index" :href="`mailto:${email}`">{{email}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="partners__ui">
              <div
                class="partners__cities"
                v-click-outside="switchDropOut"
              >
                <select class="searchable" v-show="false"></select>
                <div class="chosen-container chosen-container-single" style="width: 311px;">
                  <a v-if="currentCity"
                    class="chosen-single"
                    @click="displayDropOut=!displayDropOut"
                  >
                    <input class="chosen-focus-input" type="text" autocomplete="off" id="city">
                    <label for="city">{{currentCity.name}}</label>
                    <div class="select-arrow"><b></b></div>
                  </a>
                  <div
                    class="chosen-drop"
                    v-show="displayDropOut"
                  >
                    <div class="chosen-search">
                      <input
                        class="chosen-search-input"
                        type="text"
                        autocomplete="off"
                        data-index="cities"
                        @keyup="insertedSearchFilter($event, 'cities')"
                        @keypress.enter="autoInsertedSearchFilter($event)"
                        id = 'input-def'
                      >
                    </div>
                    <ul class="chosen-results">
                      <li
                        class="active-result"
                        v-for="(city, index) in cities"
                        :key="index"
                        v-show="showBySearch('cities', city)"
                        @click="onChange($event, city)"
                      >{{city.name}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <label class="double">
                <input type="checkbox" class="double__ui" @change="changePoints('exchange')">
                <span class="double__left"> Купить </span>
                <span class="double__indicator"></span>
                <span class="double__right"> Купить и заменить </span>
              </label>
              <label class="double">
                <input type="checkbox" class="double__ui" @change="changeType">
                <span class="double__left"> Масла </span>
                <span class="double__indicator"></span>
                <span class="double__right"> Антифриз/ТЖ </span>
              </label>
              <!-- <label class="custom-ui">
                <input
                    type="checkbox"
                    class="custom-ui__input"
                    @change="changePoints('authorized')"
                    :disabled="!exchange"
                    :checked="authorized"
                >
                <span class="custom-ui__presence"></span>
                <span class="custom-ui__text">Показать авторизированные центры</span>
              </label> -->
              <div class="partners__tab">
                <button
                  class="partners__button"
                  :class="{ 'partners__button-active': mobileTab === 'Map' }"
                  @click="mobileTab = 'Map'"
                >Карта</button>
                <button
                  class="partners__button"
                  :class="{ 'partners__button-active': mobileTab === 'Partners' }"
                  @click="mobileTab = 'Partners'"
                >Партнеры</button>
              </div>
            </div>
          </div>
          <div class="partners__map" v-show="showMobileTab('Map')">
            <yandex-map
                v-if="coords"
                :settings="settings"
                :coords="coords"
                :zoom="zoom || 15"
                :controls="controls"
                ref="ymap"
            >
              <ymap-marker
                v-for="(partner, index) in partners"
                :key="index"
                :marker-id="index"
                :coords="partner.coords"
                :icon="selectedMarker === partner.coords ? (partner.is_authorised ? selectedMarkerIconAuth : selectedMarkerIcon) : (partner.is_authorised ? markerIconAuth : markerIcon)"

                :hint-content="partner.signboard"
                :balloon-template="partner.balloon"

                @click="selectPartner(partner.coords)"
              ></ymap-marker>
                <!--
                  :icon="selectedMarker === partner.coords ? selectedMarkerIcon : markerIcon"
                  
                  :hint-content="partners.signboard || partners.address"
                :balloon-template="partners.balloon" -->
            </yandex-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import utils from '../utils'

export default {
  name: 'widget-map',
  components: { yandexMap, ymapMarker },
  data() {
    return {
      mobileTab: 'Map',
      loading: false,
      api_cities: 'cities',
      api_points: 'points',
      type: 'tcl-oil',
      zoom: null,
      selectedMarker: null,
      selectedPartner: null,
      selectedSearch: [],
      partners: [],
      authorized: false,
      exchange: false,
      displayDropOut: false,
      currentCity: null,
      cities: null,
      controls: ['zoomControl', 'typeSelector'],
      coords: [43.123156, 131.909521],
      settings: {
        apiKey: process.env.VUE_APP_YANDEX_API_KEY,
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      markerIcon: {
        layout: 'default#image',
        imageHref: '/img/pin-ghost.svg',
        imageSize: [55, 73],
        imageOffset: [-27.5, -73],
      },
      markerIconAuth: {
        layout: 'default#image',
        imageHref: '/img/pin-ghost-auth.svg',
        imageSize: [55, 73],
        imageOffset: [-27.5, -73],
      },
      selectedMarkerIcon: {
        layout: 'default#image',
        imageHref: '/img/pin.svg',
        imageSize: [116, 155],
        imageOffset: [-58, -155],
      },
      selectedMarkerIconAuth: {
        layout: 'default#image',
        imageHref: '/img/pin-auth.svg',
        imageSize: [116, 155],
        imageOffset: [-58, -155],
      },
    }
  },
  computed: {
    storeCities () {
      return this.$store.state.cities
    },
    selectCity () {
      return this.$store.state.select_city
    }
  },
  watch: {
    storeCities(value) {
      this.cities = value
    },
    selectCity(value) {
      this.currentCity = value
      this.getPoints(value.id)
    },
  },
  methods: {
    callModal(param) {
        //console.log("Opening modal with param:", param)
        this.$store.commit('SET_MODAL_NAME_ENABLED', param)
    },
    showMobileTab(tab) {
      if (window.screen.width <= 767) {
        if (tab === this.mobileTab) return true
      } else return true
      return false
    },
    changePoints(param) {
      if (this.currentCity) {
        if (param === 'exchange') {
          this.authorized = false
          this.exchange = !this.exchange
        }
        if (param === 'authorized') this.authorized = !this.authorized
        this.getPoints(this.currentCity.id)
      }
    },
    async changeType() {
      if (this.type === 'tcl-oil') this.type = 'tcl'
      else this.type = 'tcl-oil'
      await this.getCities()
      await this.getPoints(this.currentCity.id)
    },
    switchDropOut() {
      this.displayDropOut = false
    },
    autoInsertedSearchFilter(event) {
      const parent = event.target.closest('.chosen-drop')
      const list = parent.lastChild.querySelectorAll('.active-result')
      const BreakException = {}
      try {
        list.forEach((item) => {
          const text = item.innerText.toLowerCase()
          if (text.includes(event.target.value.toLowerCase())) {
            item.click()
            throw BreakException
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e
      }
    },
    selectPartner(coords) {
      this.mobileTab = 'Map'
      this.selectedMarker = coords
      this.coords = coords
    },
    insertedSearchFilter(event, name) {
      this.selectedSearch[name] = event.target.value
      //delete this.SelectedFilters[name];
      this.$forceUpdate();
    },
    showBySearch(name, value) {
      const search = this.selectedSearch[name]
      if (!search || search.length < 1) return true
      return (value.name && value.name.toLowerCase().includes(search.toLowerCase()))
    },
    onChange(event, city) {
      this.displayDropOut = false
      this.currentCity = city
      this.getPoints(city.id)
    },
    async getCities() {
      const {fetchData} = utils()
      let api = `${this.api_cities}/${this.type}`
      let response = await fetchData('get', api)

      if (response) {
        this.cities = response
        //this.currentCity = response[0]
      }
    },
    async getPoints(city_id) {
      this.loading = true
      const { fetchData } = utils()
      let target = []

      if (this.exchange) target.push('segment=3') //СТО
      if (this.authorized) target.push('is_authorised=true')

      target = target.length ? `?${target.join('&')}` : ''
      const api = `${this.api_points}/${this.type}/${city_id}`
      const response = await fetchData('get', api + target)

      let temp = response
      let lat = 0
      let lon = 0
      for (let i = 0; i < response.length; i += 1) {
        if (!temp[i].coords) {
          temp[i].coords = [
            temp[i].address.latitude,
            temp[i].address.longitude,
          ];
          const { icon } = temp[i]
          delete temp[i].icon
          temp[i].icon = { content: icon }
          temp[i].address = temp[i].address.name

          lat = lat + temp[i].coords[0] / response.length
          lon = lon + temp[i].coords[1] / response.length
        }
      }

      if (response && response.length) {
        this.coords = [lat, lon]
      }

      this.partners = temp
      setTimeout(() => this.loading = false, 450);
    },
  },
  directives: {
    ClickOutside
  },
  created() {
    if (this.storeCities) {
      this.cities= this.storeCities
    }
    
    if (this.selectCity) {
      this.currentCity = this.selectCity
      this.getPoints(this.selectCity.id)
    }

    const lsLocation = this.$localStorage.get('LOCATION')

    if (lsLocation) {
      const local = JSON.parse(lsLocation)
      this.coords = [local.lat, local.lon]
    } else {
      this.coords = [55.4507, 37.3656]
    }
  }
}
</script>