import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        cities: null,
        select_city: null,
        location: null,
        prom: null,
        modalNameEnabled: false,
    },
    getters: {
        CITIES: state => {
            return state.cities;
        },
        MODAL_NAME_ENABLED: state => {
            return state.modalNameEnabled; 
        },
    },
    mutations: {
        SET_CITIES: (state, payload) => {
            state.cities = payload;
        },
        SELECT_CITY: (state, payload) => {
            state.select_city = payload;
        },
        SET_PROM: (state, payload) => {
            state.prom = payload;
        },
        SET_MODAL_NAME_ENABLED: (state, payload) => {
            state.modalNameEnabled = payload;
            //console.log("Modal name updated to:", state.modalNameEnabled); // Отладка
        },
    },
});

export default store

